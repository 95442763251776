@import "../../../styles/gifts/sass/basics";

.bb-colorful-slider-with-icons {
  background: $turquoise-1000;
  width: 100%;
  padding: 24px 0 84px;

  &__heading-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    position: relative;
  }
  &__heading {
    font-size: 28px;
    color: $white;
    margin-bottom: 14px;
    font-weight: bold;
  }

  :global {
    .slick-track {
      display: flex;
    }

    .slick-list {
      padding-left: 20px;
      @include media-breakpoint-up(sm) {
        padding-left: calc(50% - 250px);
      }
      @include media-breakpoint-up(md) {
        padding-left: calc(50% - 340px);
      }
      @include media-breakpoint-up(lg) {
        padding-left: calc(50% - 376px);
      }
    }

    .slick-slide {
      height: inherit;
      padding-right: 32px;
      > div {
        display: flex;
        height: 100%;
        &:focus-visible {
          outline: none;
        }
      }
    }

    .slick-dots {
      max-width: none;
      bottom: -60px;
      li {
        width: auto;
        margin: 0;
        button {
          font-size: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
          color: transparent;
          border: 0;
          padding: 0;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &:focus-visible {
            outline: revert;
          }

          &:before {
            content: ' ';
            text-align: center;
            opacity: 1;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: $turquoise-1200;
            position: relative;
          }
        }
      }
      li.slick-active button:before {
        opacity: 1;
        color: transparent;
        background: $white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 48px 0 84px;
    &__heading-wrapper {
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
      margin-bottom: 64px;
    }
    &__heading {
      flex: 1 1 0;
      margin-bottom: 0;
      font-size: 36px;
    }

    :global {
      .slick-slide {
        padding-right: 96px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    :global {
      .slick-slide {
        padding-right: 168px;
      }
    }
    &__heading {
      text-align: center;

    }
  }

  @media screen and (min-width: 1920px) {
    :global {
      .slick-slide {
        padding-right: 80px;
      }
    }
  }
}

.arrows {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: flex-end;

  &__arrow {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;

    g,
    path {
      fill: $white;
    }

    &--next {
      transform: rotate(180deg);
    }

    &--inactive {
      cursor: default;
      pointer-events: none;

      g,
      path {
        fill: $turquoise-800;
      }
    }

    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: revert;
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.slide {
  flex-grow: 1;
  padding: 20px 32px 28px 28px;
  gap: 28px;
  flex-direction: column;
  align-items: center;
  color: $white;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  &__icon {
    margin-bottom: 24px;
  }

  &__label {
    font-size: 22px;
    font-weight: bold;
  }
  &__title {
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &__description {
    font-size: 14px;
  }
  &__button {

  }

  &--green {
    background: $green-800;
    .slide__label {
      color: $green-200;
    }
  }
  &--yellow {
    background: $yellow-800;
    color: $black;
    .slide__label {
      color: $yellow-1000;
    }
  }
  &--blue {
    background: $blue-800;
    .slide__label {
      color: $blue-200;
    }
  }
  &--red {
    background: $pink-1000;
    .slide__label {
      color: $red-100;
    }
  }

  &--slide {
    width: auto!important;
  }

  &--width {
    width: 276px!important;
  }

  @media only screen and (min-width:768px){
    &--width {
      width: 676px!important;
    }
  }

  @media only screen and (min-width:992px){
    &--width {
      width: 752px!important;
    }
  }

  @include media-breakpoint-up(md) {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    padding: 40px 32px 28px 28px;
    &__icon {
      max-width: 224px;
      margin-bottom: 0;
    }
    &__title {
      margin-bottom: 4px;
      font-size: 36px;
    }
    &__description {
      font-size: 18px;
    }
    &__button {
      align-self: flex-end;
    }
  }

  @media screen and (min-width: 1920px) {
    &__label {
      font-size: 28px;
    }
    &__title {
      font-size: 48px;
    }
    &__description {
      font-size: 22px;
    }
  }
}
